// Gereral Imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

const toggleElements = document.querySelectorAll(".button_container");
const overlay = document.querySelector(".overlay");

toggleElements.forEach(function (toggleElement) {
  toggleElement.addEventListener("click", function () {
    console.log("clicked");
    overlay.classList.toggle("open");
  });
});
